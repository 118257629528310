import React, { useRef, useState, useEffect, useFocus } from 'react';
import { Row, Col, Input, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { XSectionBgBorder, Title, Paragraph, XList } from './indexStyle';
import { XContainer } from '../../styles/GlobalStyle';

// actions

function ContactUs(props) {
  const [contactUsData, setContactUsData] = useState({});
  const [ctaColor, setCtaColor] = useState('#275DF5');
  const [ctaTextColor, setCtaTextColor] = useState('#275DF5');
  useEffect(() => {
    document.body.classList.remove('headerBg');
    document.body.classList.remove('bodyStyle1');
    document.body.classList.remove('bodyStyle2');
    document.body.classList.remove('bodycontactusAfterLogin');
  }, []);

  const data = [
    {
      title: 'Location',
      avatar: <Avatar shape='square' size={45} src={'/images/location.svg'} />,
      description: 'Online-based',
    },
    {
      title: 'Email',
      avatar: <Avatar shape='square' size={45} src={'/images/email.svg'} />,
      description: (
        <a href='mailto:info.skillsprints@gmail.com'>
          info.skillsprints@gmail.com
        </a>
      ),
    },
    {
      title: 'Phone',
      avatar: <Avatar shape='square' size={45} src={'/images/phone.svg'} />,
      description: <p>+91 84518 28462 / +230 5745 0670 / +91 91374 70060</p>,
    },
  ];
  return (
    <>
      <XSectionBgBorder className='pb-5'>
        <XContainer fluid>
          <div className='pageFluid py-0 bg-transparent'>
            <Row gutter={[30, 30]} align='middle'>
              <Col xs={24} className='text-center my-4'>
                <Title className='mt-lg-4'>Contact Us</Title>
                <Paragraph>
                  Whether you’re a business looking to recruit the best global
                  talent or a job seeker aiming to take the next step in your
                  career, Skill Sprints is here to help.
                </Paragraph>
              </Col>
            </Row>
            <Row gutter={[15, 30]} align='middle'>
              <Col xs={{ span: 24, order: 2 }} lg={{ span: 11, order: 1 }}>
                <XList
                  itemLayout='horizontal'
                  dataSource={data}
                  renderItem={(item, index) => (
                    <XList.Item>
                      <XList.Item.Meta
                        avatar={item.avatar}
                        title={item.title}
                        description={item.description}
                      />
                    </XList.Item>
                  )}
                />
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                lg={{ span: 12, offset: 1, order: 2 }}
                className='text-center'>
                <img className='img-fluid' src='/ld_3 1.png' alt='' />
              </Col>
            </Row>
          </div>
        </XContainer>
      </XSectionBgBorder>
    </>
  );
}

export default ContactUs;
