import React from "react";
import styled from "styled-components";
import { ArrowRightOutlined } from "@ant-design/icons";

const CheckCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="rgb(1, 95, 202)"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round">
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
);

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
`;

const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: white;
`;

const HeroSection = styled.div`
  background-color: rgb(1, 95, 202);
  color: white;
  padding: 70px 0;
`;

const HeroContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  // text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const HeroParagraph = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
  color: #e6f0ff;
  line-height: 1.8;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Button = styled.button`
  background-color: white;
  color: rgb(1, 95, 202);
  border: none;
  padding: 14px 28px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 4px;

  &:hover {
    background-color: #e6f0ff;
    transform: translateY(-2px);
  }
`;

const Section = styled.div`
  padding: 70px 0;
`;

const SectionTitle = styled.h2`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 60px;
  color: ${(props) => (props.light ? "white" : "rgb(0, 80, 255)")};
  text-align: center;
  letter-spacing: -0.02em;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 40px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    max-width: 600px;
  }
`;

const Card = styled.div`
  background-color: #f8f9fa;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 200px;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    transform: translateY(-4px);
  }
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: rgb(1, 95, 202);
  line-height: 1.4;
`;

const CardDescription = styled.p`
  color: #4a4a4a;
  line-height: 1.8;
  margin: 0;
  flex-grow: 1;
`;

const VisionSection = styled(Section)`
  background-color: rgb(1, 95, 202);
  color: white;
`;

const VisionContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const VisionParagraph = styled.p`
  font-size: 18px;
  line-height: 1.8;
  text-align: left;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CoreValueItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  background-color: #f8f9fa;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  height: 100%;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    transform: translateY(-4px);
  }
`;

const CoreValueContent = styled.div`
  flex: 1;
`;

const LeadershipSection = styled(Section)`
  background-color: #f8f9fa;
`;

const LeadershipContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const LeadershipParagraph = styled.p`
  color: #4a4a4a;
  line-height: 1.8;
  font-size: 18px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CTASection = styled(Section)`
  padding: 80px 0;
`;

const CTAContent = styled.div`
  text-align: center;
`;

const AboutUs = () => {
  const aboutUsData = {
    overview:
      "We’re Skill Sprints, the new-age HR consultancy redefining how businesses hire and grow. Think of us as your go-to partner for connecting top-notch talent with companies that mean business. Whether you’re scaling up or finding your dream job, we’ve got the tools, expertise, and global reach to make it happen.",
    vision:
      "We’re here to shake up global recruitment. Our mission is to create a hiring process that’s fast, flexible, and efficient – no matter the industry or role.",
    services: [
      "A One-Stop Job Hub: Candidates can apply for jobs worldwide, and clients can track shortlisted talent in real time. It’s recruitment made easy.",
      "End-to-End Support: Our team of remote recruitment experts works across industries, covering every hiring need under the sun.",
      "Talent Without Borders: From tech to healthcare to finance, we deliver top talent no matter where you are or what you need.",
      "Real-Time Updates: Stay in the loop with live shortlisting. No waiting, no hassle, just quick and effective hiring.",
    ],
    coreValues: [
      "Transparency: We keep things clear and upfront – no fluff, no hidden agendas",
      "Innovation: Always leveling up with smarter tools and better strategies.",
      "Global Connections: Matching talent with opportunities worldwide.",
      "Flexibility: Whether it’s remote, hybrid, or in-office, we’ve got your back with tailored solutions.",
    ],
    leadership:
      "Skill Sprints is led by Vaishali Gonty and Jyoti Rai, two recruitment pros with a passion for modernizing how businesses hire. With expertise in HR, finance, and digital transformation, they’ve built a platform that’s all about delivering results.",
  };

  return (
    <>
      <PageWrapper>
        <HeroSection>
          <Container>
            <HeroContent>
              <HeroTitle>About Us</HeroTitle>
              <HeroParagraph>{aboutUsData.overview}</HeroParagraph>
              <a href="/contact-us">
                <Button>
                  Get in touch
                  <ArrowRightOutlined size={16} style={{ marginLeft: "8px" }} />
                </Button>
              </a>
            </HeroContent>
          </Container>
        </HeroSection>

        <Section>
          <Container>
            <SectionTitle>Our Services</SectionTitle>
            <Grid>
              {aboutUsData.services.map((service, index) => {
                const [title, description] = service.split(":");
                return (
                  <Card key={index}>
                    <CardTitle>{title}</CardTitle>
                    <CardDescription>{description}</CardDescription>
                  </Card>
                );
              })}
            </Grid>
          </Container>
        </Section>

        <VisionSection>
          <Container>
            <VisionContent>
              <SectionTitle light>Our Vision</SectionTitle>
              <VisionParagraph>{aboutUsData.vision}</VisionParagraph>
            </VisionContent>
          </Container>
        </VisionSection>

        <Section>
          <Container>
            <SectionTitle>Core Values</SectionTitle>
            <Grid>
              {aboutUsData.coreValues.map((value, index) => {
                const [title, description] = value.split(":");
                return (
                  <CoreValueItem key={index}>
                    <CheckCircle size={24} color="rgb(1, 95, 202)" />
                    <CoreValueContent>
                      <CardTitle>{title}</CardTitle>
                      <CardDescription>{description}</CardDescription>
                    </CoreValueContent>
                  </CoreValueItem>
                );
              })}
            </Grid>
          </Container>
        </Section>

        <LeadershipSection>
          <Container>
            <LeadershipContent>
              <SectionTitle>Leadership</SectionTitle>
              <LeadershipParagraph>
                {aboutUsData.leadership}
              </LeadershipParagraph>
            </LeadershipContent>
          </Container>
        </LeadershipSection>

        <CTASection>
          <Container>
            <CTAContent>
              <SectionTitle>
                Whether you’re hiring or hunting for your next big opportunity,
                we’re here to help. Let’s build something amazing together.
              </SectionTitle>
              <a href="/contact-us">
                <Button style={{ border: "1px solid rgb(1, 95, 202)" }}>
                  Get in touch
                  <ArrowRightOutlined size={16} style={{ marginLeft: "8px" }} />
                </Button>
              </a>
            </CTAContent>
          </Container>
        </CTASection>
      </PageWrapper>
    </>
  );
};

export default AboutUs;
