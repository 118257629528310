import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import JobCard from '../../components/JobCards/index';
import JobDetails from '../../components/JobDescription/index';
import SearchSection from '../../components/SearchSection/index';
import {
  Col,
  notification,
  Pagination,
  Radio,
  Row,
  DatePicker,
  Select,
  Button,
  Dropdown,
  Slider,
  Drawer,
} from 'antd';
import { connect } from 'react-redux';
import {
  getCandidateJobListApi,
  getCandidateJobListReset,
} from '../../actions/getCandidateJobListAction';
import {
  get,
  getCandidateJobListGuestApi,
  getCandidateJobListGuestReset,
} from '../../actions/getCandidateJobListGuestAction';
import { addCandidateJobApi } from '../../actions/addCandidateJobAction';
import { getCandidateApplicationsByIdApi } from '../../actions/getCandidateApplicationsByIdAction';
import { checkModulePermissions, isLogin } from '../../utils/helper';
import { useForm } from 'antd/es/form/Form';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
import { XForm } from '../Login/LoginStyle';
import { getIndustryCategoriesApi } from '../../actions/getIndustryCategoriesAction';
import { getIndustriesApi } from '../../actions/getIndustriesAction';
import { getEmployerOrganizationsListApi } from '../../actions/getEmployerOrganizationsListAction';
import {
  ContentContainer,
  FilterButton,
  FilterContainer,
  Header,
  JobCount,
  JobListContainer,
  PageContainer,
  PaginationContainer,
  StyledPagination,
  XContainer,
  XSelect,
} from '../../styles/GlobalStyle';
import { filterByLabel } from '../../utils/formFunctions';
import {
  rangePresets,
  jobFilters,
  employment_type_list,
  work_type_list,
} from '../../utils/constants';
import {
  addRemoveSavedJobsApi,
  addRemoveSavedJobsReset,
} from '../../actions/addRemoveSavedJobsAction';
const { RangePicker } = DatePicker;

const JobListingPage = (props) => {
  const defaultFilters = {
    page: 1,
    limit: 10,
    search: '',
    state: '',
    category: '',
    posted: '',
    industry: '',
    joined_on: '',
    salary_min: '',
    salary_max: '',
    sortDirection: null,
  };

  const {
    getCandidateJobList,
    getCandidateJobListState,
    getCandidateJobListReset,
    addCandidateJobState,
    addCandidateJob,
    getCandidateApplicationsByIdState,
    getCandidateApplicationsById,
    getIndustryCategories,
    getIndustryCategoriesState,
    getIndustries,
    getIndustriesState,
    getEmployerOrganizationsList,
    getEmployerOrganizationsListState,
    addRemoveSavedJobsState,
    addRemoveSavedJobs,
    addRemoveSavedJobsReset,
    getCandidateJobListGuest,
    getCandidateJobListGuestState,
    getCandidateJobListGuestReset,
  } = props;
  const [form] = useForm();

  const [selectedJob, setSelectedJob] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [candidateDetail, setCandidateDetails] = useState({
    name: '',
    email: '',
  });
  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();

  const [filters, setFilters] = useState(initialFilters);
  // const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState('');
  const [open, setOpen] = useState(false);
  const [currentJobs, setCurrentJobs] = useState(null);
  const [candidateApplicationIds, setCandidateApplicationIds] = useState([]);

  const jobsPerPage = 4;

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setFilters((prev) => ({ ...prev, page: page }));
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    if (isSmallScreen) {
      setIsDrawerVisible(true);
    }
  };

  const handleAddCandidateJob = (id) => {
    //id here includes employer_id, job_id
    let data = { ...id, ...candidateDetail };
    if (id) addCandidateJob(data);
  };

  const showDrawer = () => {
    getEmployerOrganizationsList({});
    getIndustries({});
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (key) => {
    setFilters({
      ...filters,
      [key.target.name]: key.target.value,
    });
  };
  const handleJobBookmarkToggle = (jobId) => {
    const updatedJobs = currentJobs.map((job) => {
      if (job._id === jobId) {
        setSelectedJob({ ...selectedJob, is_bookmarked: !job.is_bookmarked });
        return {
          ...job,
          is_bookmarked: !job.is_bookmarked,
        };
      }
      return job;
    });

    // Update the state with the modified job list
    setCurrentJobs(updatedJobs);

    // Call the API to add/remove from saved jobs (if needed)
    addRemoveSavedJobs({ job_id: jobId });
  };

  const handleSortChange = (value, direction) => {
    setFilters({
      ...filters,
      sortBy: value,
      sortDirection: direction,
    });
  };

  const handleDateChange = (dateRange) => {
    if (dateRange) {
      setFilters({
        ...filters,
        page: 1,
        posted: [
          dateRange[0].format('YYYY-MM-DD'),
          dateRange[1].format('YYYY-MM-DD'),
        ],
      });
    } else {
      setFilters({
        ...filters,
        posted: [],
      });
    }
  };

  const handleCategorySearch = (search) => {
    if (search.length >= 3) {
      getIndustryCategories({
        search: search,
        industries: filters.industry ? [filters.industry] : null,
      });
    } else if (search.length == 0) {
      getIndustryCategories({
        industries: [filters.industry],
      });
    }
  };
  const applyFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through filters and update the query parameters
    Object.entries(filters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the current value
        queryParams.append(key, value);
      }
    });

    // Set the page to 1
    queryParams.set('page', '1');
    filters.page = 1;

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const clearFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through defaultFilters and update the query parameters
    Object.entries(defaultFilters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the default value
        queryParams.append(key, value);
      }
    });

    // Clear the form fields
    form.resetFields();

    // Update the state with default values
    setFilters(defaultFilters);

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });
    // Make API call with updated filters and pagination
    if (isLogin()) getCandidateJobList({ ...updatedFilters });
    else getCandidateJobListGuest({ ...updatedFilters });
    if (checkModulePermissions('explore').sub_modules.includes('list-jobs')) {
      getCandidateApplicationsById();
    }
  }, [window.location.search]);

  useEffect(() => {
    if (getCandidateJobListState.apiState === 'success') {
      setTotalRecords(getCandidateJobListState.data.totalCount);
      setSelectedJob(getCandidateJobListState.data.list[0]);
      setCurrentJobs(
        getCandidateJobListState.data.list.slice(
          indexOfFirstJob,
          indexOfLastJob
        )
      );
      getCandidateJobListReset();
    } else if (getCandidateJobListState.apiState === 'error') {
      console.log('API Error=>', getCandidateJobListState.message);
      notification.error({
        message: 'Unable to get Job List',
      });
      getCandidateJobListReset();
    }
  }, [getCandidateJobListState.apiState]);

  useEffect(() => {
    if (getCandidateJobListGuestState.apiState === 'success') {
      setTotalRecords(getCandidateJobListGuestState.data.totalCount);
      setSelectedJob(getCandidateJobListGuestState.data.list[0]);
      setCurrentJobs(
        getCandidateJobListGuestState.data.list.slice(
          indexOfFirstJob,
          indexOfLastJob
        )
      );
      getCandidateJobListGuestReset();
    } else if (getCandidateJobListGuestState.apiState === 'error') {
      console.log('API Error=>', getCandidateJobListGuestState.message);
      notification.error({
        message: 'Unable to get Job List',
      });
      getCandidateJobListGuestReset();
    }
  }, [getCandidateJobListGuestState.apiState]);

  useEffect(() => {
    if (getCandidateApplicationsByIdState.apiState === 'success') {
      let candidateDetails = getCandidateApplicationsByIdState.data.candidate;
      if (candidateDetails) {
        setCandidateDetails({
          name: candidateDetails.first_name + ' ' + candidateDetails.last_name,
          email: candidateDetails.email_address,
        });
      }
      getCandidateApplicationsByIdState.data?.applications?.map(
        (ele, index) => {
          setCandidateApplicationIds((prevState) => [
            ...prevState,
            ele.job_inventory_id,
          ]);
        }
      );
    }
  }, [getCandidateApplicationsByIdState.apiState]);

  useEffect(() => {
    if (addCandidateJobState.apiState === 'success') {
      getCandidateApplicationsById();
    }
  }, [addCandidateJobState.apiState]);

  // useEffect(() => {
  //   if (addRemoveSavedJobsState.apiState === "success") {
  //     const savedJobs = addRemoveSavedJobsState.data?.job_ids || []; // Array of saved job IDs
  //     const isJobSaved = savedJobs.includes(job._id); // Check if the job is bookmarked
  //     setIsBookmarked(isJobSaved); // Set isBookmarked based on whether job._id is in saved jobs
  //   }
  // }, [addRemoveSavedJobsState.apiState]);

  useEffect(() => {
    const handleResize = () => {
      const shouldShowDrawer = window.innerWidth <= 1024;
      setIsSmallScreen(shouldShowDrawer);
      // if (!shouldShowDrawer && !selectedJob) {
      //   setSelectedJob(jobsData[0]);
      // }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <XContainer fluid>
        <CaseSearch
          searchOption='reseller'
          title='Resellers'
          filters={[
            <XForm.Item
              name='industry'
              colon={false}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              label='Industry'
              className='removeFormMargin'>
              <XSelect
                showSearch
                size='large'
                className='removeBg'
                onFocus={() => getIndustries({})}
                defaultValue={filters.industry || null}
                filterOption={filterByLabel}
                onSearch={(search) => {
                  if (search.length >= 3) {
                    getIndustries({ search });
                  } else {
                    getIndustries({});
                  }
                }}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    industry: value,
                  });
                  getIndustryCategories({ industries: [value] });
                  form.setFieldsValue({ category: null });
                }}
                onClear={() => {
                  setFilters({
                    ...filters,
                    industry: '',
                  });
                }}
                options={
                  getIndustriesState.apiState === 'success' &&
                  getIndustriesState.data.all_industries.length > 0 &&
                  getIndustriesState.data.all_industries.map((ind) => {
                    return {
                      value: ind._id,
                      label: ind.industry_name,
                      type: 'industry',
                    };
                  })
                }
                placeholder='Select Industry'
              />
            </XForm.Item>,
            <XForm.Item
              colon={false}
              name='category'
              label='Category'
              className='selectBox'>
              <XSelect
                name='category'
                size='large'
                placeholder='Search Functions'
                className='removeShadow ml-2'
                showSearch
                defaultValue={filters.category || null}
                allowClear={true}
                onSearch={handleCategorySearch}
                onChange={(category) => {
                  setFilters({
                    ...filters,
                    category,
                  });
                }}
                filterOption={filterByLabel}
                options={
                  getIndustryCategoriesState.apiState === 'success' &&
                  getIndustryCategoriesState.data.all_categories.length > 0
                    ? getIndustryCategoriesState.data.all_categories.map(
                        (item) => {
                          return {
                            value: item.id,
                            label: item.category_name,
                          };
                        }
                      )
                    : []
                }></XSelect>
            </XForm.Item>,
            <XForm.Item
              name='employer_id'
              label='Employer'
              className='selectBox'>
              <XSelect
                name='employer_id'
                className='removeShadow ml-2'
                onFocus={() => getEmployerOrganizationsList({})}
                defaultValue={filters.employer_id || null}
                filterOption={filterByLabel}
                onSearch={(search) => {
                  if (search.length >= 3) {
                    getEmployerOrganizationsList({ search });
                  } else {
                    getEmployerOrganizationsList({});
                  }
                }}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    employer_id: value,
                  });
                }}
                onClear={() => {
                  setFilters({
                    ...filters,
                    employer_id: '',
                  });
                }}
                size='large'
                options={
                  getEmployerOrganizationsListState.apiState === 'success' &&
                  getEmployerOrganizationsListState.data.all_organizations
                    .length > 0
                    ? getEmployerOrganizationsListState.data.all_organizations.map(
                        (item) => {
                          return {
                            value: item._id,
                            label: item.organization_name,
                          };
                        }
                      )
                    : []
                }></XSelect>
            </XForm.Item>,
            <XForm.Item name='salary' label='Salary' className='selectBox'>
              <Slider
                range
                min={10000}
                max={10000000}
                step={10000}
                defaultValue={[
                  filters.salary_min || 2000000,
                  filters.salary_max || 5000000,
                ]}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    salary_min: value[0],
                    salary_max: value[1],
                  });
                }}
              />
            </XForm.Item>,
            <XForm.Item
              colon={false}
              label='Employment Type'
              className='formLable'
              name='employment_type'>
              <XSelect
                size='large'
                placeholder='Employment Type'
                className='removeBg'
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    employment_type: value,
                  });
                }}
                onClear={() => {
                  setFilters({
                    ...filters,
                    employment_type: '',
                  });
                }}
                defaultValue={filters.employment_type || null}
                options={employment_type_list}
              />
            </XForm.Item>,
            <XForm.Item
              colon={false}
              label='Work Type'
              className='formLable'
              name='work_type'>
              <XSelect
                size='large'
                placeholder='Work Type'
                className='removeBg'
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    work_type: value,
                  });
                }}
                onClear={() => {
                  setFilters({
                    ...filters,
                    work_type: '',
                  });
                }}
                defaultValue={filters.work_type || null}
                options={work_type_list}
              />
            </XForm.Item>,
            <XForm.Item
              name='posted'
              colon={false}
              label='By Date'
              className='removeFormMargin'>
              <XForm.Item colon={false} name='posted' className='selectBox'>
                <RangePicker
                  size='large'
                  className='removeShadow ml-2'
                  defaultValue={filters.posted || null}
                  presets={rangePresets}
                  onChange={handleDateChange}
                  allowClear
                  style={{
                    borderRadius: '50px',
                    height: '40px',
                  }}></RangePicker>
              </XForm.Item>
            </XForm.Item>,
            <XForm.Item
              colon={false}
              name='sortBy'
              label='Sort By'
              className='selectBox'>
              <Dropdown
                className='removeShadow w-100  ms-0'
                trigger={['click']}
                menu={{
                  items: jobFilters(handleSortChange), // Pass the dynamic function here
                }}>
                <Button
                  className='shortBtn'
                  style={{
                    border: 'none',
                    padding: 10,
                    height: '40px',
                    width: '110px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #d9d9d9',
                    color: '#000',
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  <p>Sort</p>
                  <img className='mt-1' src='/images/short-icon.svg' alt='' />
                </Button>
              </Dropdown>
            </XForm.Item>,
          ]}
          onClose={onClose}
          open={open}
          applyFilters={applyFilters}
          form={form}
          clearFilters={clearFilters}
        />
        <SearchSection setFilters={setFilters} filters={filters} />
        <PageContainer style={{ padding: '40px 0' }}>
          <Header
            className='align-items-center flex-wrap'
            style={{
              margin: '10px 0px',
            }}>
            <JobCount>{totalRecords || 0} Jobs</JobCount>
            <FilterContainer onClick={() => showDrawer()}>
              <FilterButton>
                <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/d0e11f66e694b5fbdadd8edb8109ba7b24c06cb1f66c477f282ec451dfd237e7?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                  alt='Filter icon'
                />
                Filter
              </FilterButton>
            </FilterContainer>
          </Header>
          <ContentContainer>
            <JobListContainer>
              {currentJobs &&
                currentJobs.length > 0 &&
                currentJobs.map((job) => (
                  <JobCard
                    key={job._id}
                    {...job}
                    isSelected={job._id === selectedJob._id}
                    onClick={() => handleJobClick(job)}
                  />
                ))}
            </JobListContainer>
            {/* {!isDrawerVisible && (
          <JobDetailsContainer>
            <JobDetails job={selectedJob} />
          </JobDetailsContainer>
        )} */}
            {/* <JobDetails
            job={selectedJob}
            handleAddCandidateJob={handleAddCandidateJob}
            candidateApplicationIds={candidateApplicationIds}
            onBookmarkToggle={handleJobBookmarkToggle} // Ensure this is passed here
            is_bookmarked={selectedJob?.is_bookmarked}
          /> */}
            {!isSmallScreen ? (
              <JobDetails
                job={selectedJob}
                handleAddCandidateJob={handleAddCandidateJob}
                candidateApplicationIds={candidateApplicationIds}
                onBookmarkToggle={handleJobBookmarkToggle} // Ensure this is passed here
                is_bookmarked={selectedJob?.is_bookmarked}
              />
            ) : (
              <Drawer
                headerStyle={{ fontSize: '20px' }}
                title='Job Detail'
                bodyStyle={{ padding: '0' }}
                width={500}
                open={isDrawerVisible && selectedJob !== null}
                onClose={handleCloseDrawer}>
                <JobDetails
                  isDrawer={true}
                  job={selectedJob}
                  handleAddCandidateJob={handleAddCandidateJob}
                  candidateApplicationIds={candidateApplicationIds}
                  onBookmarkToggle={handleJobBookmarkToggle} // Ensure this is passed here
                  is_bookmarked={selectedJob?.is_bookmarked}
                />
              </Drawer>
            )}
          </ContentContainer>
          <PaginationContainer>
            <StyledPagination
              current={currentPage}
              total={totalRecords}
              pageSize={jobsPerPage}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </PaginationContainer>
        </PageContainer>
      </XContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  getCandidateJobListState: state.getCandidateJobList,
  getCandidateJobListGuestState: state.getCandidateJobListGuest,
  getCandidateApplicationsByIdState: state.getCandidateApplicationsById,
  addCandidateJobState: state.addCandidateJob,
  getIndustryCategoriesState: state.getIndustryCategories,
  getIndustriesState: state.getIndustries,
  getEmployerOrganizationsListState: state.getEmployerOrganizationsList,
  addRemoveSavedJobsState: state.addRemoveSavedJobs,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateJobList: (params) => dispatch(getCandidateJobListApi(params)),
  getCandidateJobListGuest: (params) =>
    dispatch(getCandidateJobListGuestApi(params)),
  getCandidateJobListReset: () => dispatch(getCandidateJobListReset()),
  getCandidateJobListGuestReset: () =>
    dispatch(getCandidateJobListGuestReset()),
  getCandidateApplicationsById: (params) =>
    dispatch(getCandidateApplicationsByIdApi(params)),
  addCandidateJob: (params) => dispatch(addCandidateJobApi(params)),
  getIndustryCategories: (params) => dispatch(getIndustryCategoriesApi(params)),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
  getEmployerOrganizationsList: (params) =>
    dispatch(getEmployerOrganizationsListApi(params)),
  addRemoveSavedJobs: (params) => dispatch(addRemoveSavedJobsApi(params)),
  addRemoveSavedJobsReset: () => dispatch(addRemoveSavedJobsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobListingPage);
